import PartnersNavbar from '../partners-navbar/partners-navbar';
import { css } from '@emotion/react';
import RootLayout from 'root-layout';

export default function PartnersLayout(props: any) {
  return (
    <RootLayout>
      <PartnersNavbar />
      <div
        css={css`
          padding-top: 64px;
        `}
      >
        {props.children}
      </div>
    </RootLayout>
  );
}
