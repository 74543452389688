import { Typography } from 'antd';
import styled from '@emotion/styled';

const Title = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 300;
    margin: 1em 0;
    font-size: 34px;
  }
`;

export default Title;
