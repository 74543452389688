import DashboardNavbar from '../dashboard-navbar/dashboard-navbar';
import { css } from '@emotion/react';
import RootLayout from 'root-layout';

export default function DashboardLayout(props: any) {
  return (
    <RootLayout>
      <DashboardNavbar />
      <div
        css={css`
          padding-top: 64px;
        `}
      >
        {props.children}
      </div>
    </RootLayout>
  );
}
