import ContentBox from './content-box/content-box';
import ContentDivider from './content-divider/content-divider';
import ContentHeaderActions from './content-header-actions/content-header-actions';
import ContentHeader from './content-header/content-header';
import Content from './content/content';

const Layout = {
  Content,
  ContentHeader,
  ContentHeaderActions,
  ContentBox,
  ContentDivider,
};

export default Layout;
