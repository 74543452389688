import ButtonLink from './button/button-link/button-link';
import Layout from './layout';
import Typography from './typography';

const colors = {
  primary: '#2859e2',
  primaryHover: '#1946c1',
  error: '#f81d22',
};

export { colors, Layout, Typography, ButtonLink };
