import styled from '@emotion/styled';
import { Layout } from 'antd';

type ContentProps = { fullwidth?: boolean };

const Content = styled(Layout.Content, {
  shouldForwardProp(propName: string) {
    return !['fullwidth'].includes(propName);
  },
})`
  max-width: ${(props: ContentProps) => (props.fullwidth ? '100%' : '1360px')};
  margin: ${(props: ContentProps) => (props.fullwidth ? '0 32px' : '0 auto')};

  @media (max-width: 576px) {
    margin: 0 12px;
  }
`;

export default Content;
