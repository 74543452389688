import styled from '@emotion/styled';

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.as-column {
    display: block;
  }
`;

export default ContentHeader;
