import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link, useMatch } from '@reach/router';
import { colors } from 'theme';

const MenuLink = styled(Link)`
  display: block;
  color: #fff;
  padding: 0 24px;
  height: 100%;
  line-height: 60px;

  &:hover {
    background: ${colors.primaryHover};
    color: #fff;
  }
`;

export default function PartnersNavbar() {
  const params = useMatch('/partners/:accessToken/*');

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        width: 100%;
        height: 64px;
        background: ${colors.primary};
        display: flex;
        align-items: center;
        z-index: 100;
      `}
    >
      <span
        css={css`
          display: block;
          line-height: 60px;
          color: #fff;
          font-weight: bold;
          padding: 0 48px;
          text-transform: uppercase;
          letter-spacing: 1px;
        `}
      >
        Dashboard
      </span>
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 100%;
        `}
      >
        <MenuLink to={`/partners/${params?.accessToken}/questionnaires/instances`}>Instances</MenuLink>
      </div>
    </div>
  );
}
