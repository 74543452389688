import './index.css';
import reportWebVitals from './reportWebVitals';
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Router } from '@reach/router';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import DashboardLayout from 'modules/dashboard/components/dashboard-layout/dashboard-layout';
import PartnersLayout from 'modules/partners/components/partners-layout/partners-layout';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

require('dayjs/locale/sl');

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.locale('sl');

const AsyncCustomersDashboard: any = React.lazy(() => import('./modules/customers/dashboard'));
const AsyncQuestionnairesDashboard: any = React.lazy(() => import('./modules/questionnaires/dashboard'));
const AsyncQuestionnairesPartners: any = React.lazy(() => import('./modules/questionnaires/partners'));
const AsyncAuth: any = React.lazy(() => import('./modules/auth'));
const AsyncQuestionnaires: any = React.lazy(() => import('./modules/questionnaires'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      }
    >
      <Router primary={true}>
        <AsyncAuth path="/auth/**" />
        <AsyncQuestionnaires path="/q/:accessKey" />
        <DashboardLayout path="/dashboard">
          <AsyncCustomersDashboard path="customers" />
          <AsyncQuestionnairesDashboard path="questionnaires/*" />
        </DashboardLayout>
        <PartnersLayout path="/partners/:accessToken">
          <AsyncQuestionnairesPartners path="questionnaires/*" />
        </PartnersLayout>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
