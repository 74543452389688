import { Link } from '@reach/router';
import { ButtonProps } from 'antd';

export default function ButtonLink({
  to,
  icon,
  children,
  type,
  className,
  ...restProps
}: {
  to: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  type?: ButtonProps['type'];
  className?: string;
}) {
  return (
    <Link to={to} className={`ant-btn ${icon && !children ? 'ant-btn-icon-only' : ''} ${type ? `ant-btn-${type}` : ''} ${className}`} {...restProps}>
      {icon}
      {children}
    </Link>
  );
}
