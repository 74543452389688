import { css } from '@emotion/react';
import { Typography } from 'antd';
import React from 'react';

export default function ContentDivider({ title }: { title: string }) {
  return (
    <Typography.Title
      css={css`
        &.ant-typography {
          margin-top: 35px;
          margin-bottom: 15px;
          border-bottom: 1px solid #ddd;
          padding-bottom: 5px;
          text-transform: uppercase;
          font-size: 15px;
          color: #444;
          letter-spacing: 0.5px;

          &:first-of-type {
            margin-top: 0;
          }
        }
      `}
      level={4}
    >
      {title}
    </Typography.Title>
  );
}
