import { css } from '@emotion/react';
import React from 'react';
import { Children } from 'types';

export default function ContentBox({ children }: { children: Children }) {
  return (
    <div
      css={css`
        background: #fff;
        padding: 24px;
        border: 1px solid #ddd;
        border-top: none;

        > .ant-form-error {
          margin: -24px -24px 24px -24px;
        }
      `}
    >
      {children}
    </div>
  );
}
