import styled from '@emotion/styled';

const ContentHeaderActions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 10px;

    &:first-of-type(button) {
      margin-left: 0;
    }
  }
`;

export default ContentHeaderActions;
