import { Global, css } from '@emotion/react';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function RootLayout({ children }: { children: React.ReactChildren | React.ReactChild | JSX.Element[] }) {
  return (
    <>
      <Global
        styles={css`
          html {
            font-family: 'Roboto', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          html,
          body {
            background: #e7e7e7;
            color: #222;
          }
        `}
      />
      <HelmetProvider context={{}}>
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet" />
        </Helmet>
      </HelmetProvider>
      {children}
    </>
  );
}
